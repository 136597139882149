import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
import ContentBlock from 'src/components/contentBlock';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "image"
    }}>{`Image`}</h1>
    <p>{`Import images by dragging them into the `}<inlineCode parentName="p">{`Assets.xcassets`}</inlineCode>{` directory. They can then be referenced by name: `}</p>
    <pre><code parentName="pre" {...{
        "className": "language-clike"
      }}>{`Image("ExampleImage") // You may have to restart Xcode to update the canvas preview.
`}</code></pre>
    <p>{`You can import varying image resolutions (1x, 2x, 3x) to automatically optimize renders on different devices. `}<a parentName="p" {...{
        "href": "https://developer.apple.com/design/human-interface-guidelines/ios/icons-and-images/image-size-and-resolution/"
      }}>{`[Learn More]`}</a></p>
    <h2 {...{
      "id": "sf-symbols-icons"
    }}>{`SF Symbols (Icons)`}</h2>
    <p>{`Access Apple's `}<a parentName="p" {...{
        "href": "https://developer.apple.com/design/human-interface-guidelines/sf-symbols/overview/"
      }}>{`system icons`}</a>{` with the `}<inlineCode parentName="p">{`systemName`}</inlineCode>{` param, with similar styling modifiers as `}<inlineCode parentName="p">{`Text`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-clike"
      }}>{`Image(systemName: "message.circle.fill")

Image(systemName: "message.circle.fill")
    .font(.largeTitle)
    .foregroundColor(.blue)
`}</code></pre>
    <h2 {...{
      "id": "resizable"
    }}>{`.resizable()`}</h2>
    <p>{`Size image to available space, often used with `}<inlineCode parentName="p">{`.aspectRatio()`}</inlineCode>{` to avoid undesired stretching. Can tile the entire or part of the image with `}<inlineCode parentName="p">{`renderingMode: .tile`}</inlineCode>{`.`}</p>
    <ContentBlock imgName="Image-resizable.png" mdxType="ContentBlock">
  <code className="clike">
    {`
/* 
    func resizable(capInsets: EdgeInsets = EdgeInsets(), resizingMode: Image.ResizingMode = .stretch) -> Image
*/
VStack(spacing: 20) {
    Image("Bangkok") // unsplash.com/photos/g5Uh7nP60FA
            .resizable()
            .frame(width: 350, height:100) // contain element within a view of 350x100\n
    Image("Bangkok")
        .resizable()
        .frame(width: 200, height:100)\n
    Image("SwiftUI") // developer.apple.com/assets/elements/icons/swiftui/swiftui-96x96.png
        .resizable(resizingMode: .tile) // default: .stretch
        .frame(width: 200, height: 100)\n
    // tile image offsets (... but why would you ever do this?)
    Image("SwiftUI")
        .resizable(capInsets: EdgeInsets(top: 50, leading: 0, bottom: 0, trailing: 0), resizingMode: .tile)
        .frame(width: 350, height: 200)
}
    `}
  </code>
    </ContentBlock>
    <h2 {...{
      "id": "aspectratio"
    }}>{`.aspectRatio()`}</h2>
    <p>{`Adjust an image's aspect ratio and indicate whether it should "fill" or "fit into" all available space. You can also use the `}<inlineCode parentName="p">{`.scaledToFit()`}</inlineCode>{` and `}<inlineCode parentName="p">{`.scaledToFill()`}</inlineCode>{` aliases to select a `}<inlineCode parentName="p">{`contentMode`}</inlineCode>{` without modifying the image's original aspect ratio.`}</p>
    <ContentBlock imgName="Image-aspectRatio.png" mdxType="ContentBlock">
  <code className="clike">
    {`
/* 
    func aspectRatio(_ aspectRatio: CGFloat? = nil, contentMode: ContentMode) -> some View
*/
VStack(spacing: 20) {
    Image("Bangkok")
        .resizable()
        .aspectRatio(contentMode: .fill) // .scaledToFill()
        .frame(width: 200, height: 200)
        .border(Color.red, width: 3)\n
    Image("Bangkok")
        .resizable()
        .aspectRatio(contentMode: .fit) // .scaledToFit()
        .frame(width: 200, height: 200)
        .border(Color.red, width: 3)\n
    Image("Bangkok")
        .resizable()
        .aspectRatio(0.25, contentMode: .fit)
        .frame(width: 200, height: 200)
        .border(Color.red, width: 3)
}
    `}
  </code>
    </ContentBlock>
    <h2 {...{
      "id": "scaleeffect"
    }}>{`.scaleEffect()`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-clike"
      }}>{`Image("Bangkok")
    .resizable()
    .scaleEffect(2)
    .frame(width: 200, height: 200) // makes actual dimensions 400x400
`}</code></pre>
    <h2 {...{
      "id": "rotationeffect"
    }}>{`.rotationEffect()`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-clike"
      }}>{`Image("Bangkok")
    .resizable()
    .rotationEffect(.degrees(100)) // can also use .radians()
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      